import React, { Suspense, useEffect } from "react";
import "./App.css";
import RoutingComponent from "./Routes/routing";

function App() {
  return (
    <Suspense fallback={<h3>Loading...</h3>}>
      <RoutingComponent />
    </Suspense>
  );
}

export default App;
