import { KYCREJECTMODALLOADER, SETLOADING } from "./type";

const initialState = {
    isLoading: false,
    kycRejectModalOpenStatus: false
};

const loadingReducer = (state = initialState, action: { type: string, payload: boolean }) => {
    const newState = { ...state };
    switch (action.type) {
        case SETLOADING:
            newState.isLoading = action.payload;
            break;

        case KYCREJECTMODALLOADER:
            newState.kycRejectModalOpenStatus = action.payload;
            break;
    }
    return newState;
};

export { loadingReducer };

