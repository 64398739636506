import axios from "axios";
import { environment } from "../environment";
import store from "../store";
import { onLoginAfterTokenExpiry } from "../store/auth/action";
import { setLoading } from "../store/loader/action";
import { getDecryptedLocalStorage, toastError } from "./common-functions";
import { localstorageKeys, validationMessages } from "./constants";

const axiosInstance = axios.create({
  timeout: 1000 * 15,
});

// axiosInstance.defaults.baseURL = environment.APIURL + "api/";
axiosInstance.defaults.baseURL = environment.newApiUrl;
axiosInstance.interceptors.request.use(
  (req) => {
    // check for token
    const userToken = getDecryptedLocalStorage(localstorageKeys.userToken);
    if (userToken) {
      req.headers.Authorization = userToken;
    }
    return req;
  },
  (err) => {
    // catches client side error like no internet etc
    return Promise.reject(err);
  }
);

axiosInstance.interceptors.response.use(
  (req) => {
    // setisLoaderActive(false);
    return req;
  },
  (err) => {
    if (err.code === "ECONNABORTED") {
      store.dispatch(setLoading(false));
      toastError(validationMessages.connectionTimeOut);
    } else {
      store.dispatch(onLoginAfterTokenExpiry());
    }
    return Promise.reject(err);
  }
);

export default class HTTPService {
  static get(url, params) {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(url, {
          params,
        })
        .then((response) => resolve(response.data))
        .catch((error) => reject(error.response || error));
    });
  }

  static put(url, body) {
    return new Promise((resolve, reject) => {
      axiosInstance
        .put(url, body)
        .then((response) => resolve(response.data))
        .catch((error) => reject(error.response || error));
    });
  }

  static post(url, body) {
    return new Promise((resolve, reject) => {
      axiosInstance
        .post(url, body)
        .then((response) => resolve(response.data))
        .catch((error) => reject(error.response || error));
    });
  }

  static delete(url, body) {
    return new Promise((resolve, reject) => {
      axiosInstance
        .delete(url, { data: body })
        .then((response) => resolve(response.data))
        .catch((error) => reject(error.response || error));
    });
  }

  static postAccount(url, body) {
    axiosInstance.defaults.baseURL = environment.ACCOUNT_API;
    return new Promise((resolve, reject) => {
      axiosInstance
        .post(url, body)
        .then((response) => {
          axiosInstance.defaults.baseURL = environment.APIBASEURL;
          resolve(response.data);
        })
        .catch((error) => reject(error.response || error));
    });
  }
}
