import { onLogin } from "../../actions/auth";
import { localstorageKeys } from "../../common/constants";
import { ISLOGGEDIN, LOGINAFTERTOKENEXPIRY, ONLOGOUT } from "./type";


const getUserToken = () => {
    const userToken = localStorage.getItem(localstorageKeys.userToken) || '';
    if (userToken) {
        return {
            isLoggedIN: true,
            userToken
        }
    } else {
        return {
            isLoggedIN: false,
            userToken: null
        }
    }
}

export const authReducer = (state = getUserToken(), action: { type: string, payload: { isLoggedIN: boolean, userToken: string } }) => {
    let newState = { ...state };

    switch (action.type) {
        case ISLOGGEDIN:
            newState = {
                userToken: action.payload.userToken,
                isLoggedIN: true
            }
            break;

        case ONLOGOUT:
            newState = {
                userToken: null,
                isLoggedIN: false
            }
            break;

        case LOGINAFTERTOKENEXPIRY:
            onLogin().then((response) => {
                const userToken = `Bearer ${response.data.token}`;
                if (userToken) {
                    localStorage.setItem(localstorageKeys.userToken, userToken);
                    window.location.reload();
                }
            })
            break;

        default:
    }
    return newState;
}