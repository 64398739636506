import { Header } from "../Pages/Main/header";
import { SearchBar } from "../Pages/Main/searchBar";
import { Footer } from "../Pages/Main/footer";

export const ProtectedRouteComponent = ({
  Route,
  Redirect,
  privateRoutes,
  Switch,
}) => {
  return (
    <>
      <Header />
      <div className="site-main">
        <SearchBar />
        <Switch>
          {privateRoutes.map((route, i) => {
            return (
              <Route
                exact={true}
                path={route.path}
                component={route.component}
                key={i}
              />
            );
          })}
          <Redirect exact from="/" to="/dashboard" />
        </Switch>
      </div>
      <Footer />
    </>
  );
};
