import { AES, enc } from "crypto-js";
import toast from "react-hot-toast";
import { onUserLogout } from "../store/auth/action";
// import { environment } from "../environment";
// import { onUserLogout } from "../store/auth/action";
import store from "../store/index";
import { cookieKeys, localstorageKeys, toasterPosition } from "./constants";

export const toastSuccess = (message: string) => {
  toast.success(message, { position: toasterPosition, style: { color: '#000', minWidth: 150, padding: 10, fontWeight: 500, marginBottom: 60, border: '1px solid #073E84' }, iconTheme: { primary: '#073E84 ', secondary: '#fff' } });
};

export const toastError = (message: string) => {
  toast.error(message, { position: toasterPosition, style: { color: '#000', fontWeight: 500, padding: 10, marginBottom: 60, border: '1px solid #ff0000' } });
};

const getCookie = (cookieName: any) => {
  let name = cookieName + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  if (decodedCookie) {
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i].trim();
      while (c.charAt(0) === "") {
        c = c.substring(1);
      }
      if (+c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
  }
  return "";
};

export const decryptData = (data: any) => {
  const bytes = AES.decrypt(data.toString(), cookieKeys.cryptoSecretKey);
  if (bytes.toString()) {
    return JSON.parse(bytes.toString(enc.Utf8));
  }
};

export const getEncryptedCookie = (key: string) => {
  if (key) {
    const keyName = cookieKeys.cookieInitial + "-" + key.trim();
    const cookieData = getCookie(keyName);
    if (cookieData) {
      return decryptData(cookieData);
    }
  }
};

export const handleLogout = () => {
  localStorage.clear();
  store.dispatch(onUserLogout(false));
};

export const setEncryptedLocalStorage = (key: string, value: any) => {
  localStorage.setItem(key, JSON.stringify(value));
};

export const getDecryptedLocalStorage = (key: string) => {
  const userInfo = localStorage.getItem(key) || "";

  if (userInfo) {
    return userInfo;
  } else return "";
};

export const generate5DigitRandomNumber = () => {
  return Math.floor(Math.random() * 90000) + 10000;
};

export const getMinDateTomorrow = () => {
  return (
    new Date().getFullYear().toString() +
    "-" +
    (new Date().getMonth() + 1).toString() +
    "-" +
    (new Date().getDate() + 1).toString()
  );
};

export const goToPage = (url: string) => {
  window.open(url, "_blank");
};

export const getInitialDashBoardCounts = () => {
  const counts = localStorage.getItem(localstorageKeys.dashboardDataCounts);
  let result = {
    data: {},
    isEmpty: true
  }
  if (counts) {
    result.data = JSON.parse(JSON.parse(counts));
    result.isEmpty = false;
  } else {
    result.data = { txCount: 0, peerCount: 0, latestBlock: 0, chaincodeCount: 0 }
  }
  return { result };
}