import { CHANNEL_COUNT, CHANNEL_HASH, CHANNEL_LIST, CURRENT_CHANNEL, SEARCH_HASH } from "./type"

export const setChannelList = (payload: any[]) => {
    return { type: CHANNEL_LIST, payload }
}

export const setCurrentReceipt = (payload: string) => {
    return { type: CURRENT_CHANNEL, payload }
}

export const setCurrentChannelHash = (payload: string) => {
    return { type: CHANNEL_HASH, payload }
}

export const dispatchSearchResult = (payload: string) => {
    return { type: SEARCH_HASH, payload }
}

export const setChannelCounts = (payload: { txCount: number, peerCount: number, latestBlock: number, chaincodeCount: number }) => {
    return { type: CHANNEL_COUNT, payload }
}