export const environment = {
  // APIURL: "https://hlf-breceipts.io-world.com/",
  APIURL: "https://hlf-breceipts.edexa.com/",
  // newApiUrl: "http://43.205.48.43:3005/",
  newApiUrl: "https://edexawebsiteapi.io-world.com/",
  // newApiUrlTwo: "http://43.205.48.43:3001/",
  newApiUrlTwo: "https://apiexplorer.io-world.com/",
  terms_conditions: "https://edexa.com/en/terms-and-conditions/",
  privacy_policy: "https://edexa.com/en/privacy-policy/",
  loginParams: {
    user: "exploreradmin",
    password: "exploreradminpw",
    network: "first-network",
  },
};
