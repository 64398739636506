import { useState } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
    // !Do not delete below comment, as this has been commented out temporarily

// import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { ReactComponent as Closeicon } from "../../assets/images/close-icon.svg";
import { ReactComponent as Logo } from "../../assets/images/edexa-logo.svg";
import { ReactComponent as Menuicon } from "../../assets/images/menu-icon.svg";
import "../../assets/scss/layouts/header.scss";
// import { setCurrentReceipt } from '../../store/channels/action';

export const Header = () => {
    // const dispatch = useDispatch();
    const [isActive, setActive] = useState(false);
    // !Do not delete below comment, as this has been commented out temporarily

    // const { channelList, currentChannel } = useSelector((state: any) => state.channels);



    const toggleClass = () => {
        setActive(!isActive);
    };

        // !Do not delete below comment, as this has been commented out temporarily


    // const [dropdownOpen, setDropdownOpen] = useState(false);



    /* const toggle = () => {
        setDropdownOpen(prevState => !prevState);
    } */

    // !Do not delete below comment, as this has been commented out temporarily

    /* const handleChannelChange = (channel: any) => {
        dispatch(setCurrentReceipt(channel));
    } */

    return (
        <div className="header white-bg d-flex flex-wrap align-items-center justify-content-between p-3">
            <div className="leftpart d-flex flex-wrap align-items-center">
                <RouterLink to="/" className="headerlogo pe-3 borderRight-1px border-gray">
                    <Logo className="logo" />
                </RouterLink>
                <div className="header-title ps-3 f-20px f-bold dark-color">Explorer</div>
            </div>
            <div className="toggle-button d-md-none d-block" onClick={toggleClass} >
                <Menuicon className="menu-toggle" />
            </div>
            <div className={`mein-menu d-md-flex flex-md-wrap align-items-md-center ${isActive ? "show-menu" : ""}`}>
                <div className="menu-close position-absolute blue-bg d-md-none d-flex flex-wrap align-items-center justify-content-center" onClick={toggleClass}>
                    <Closeicon className="fill-white" />
                </div>
                <div className="menu f-16px pt-md-0 pt-5 pb-md-0 pb-3">
                    <div className="d-md-flex flex-md-wrap align-items-md-center">
                        {/* <RouterLink to="/" className="px-xl-4 px-3 navtext-color hover-blue-color f-bold" activeClassName="active">Blockchain</RouterLink>
                        <RouterLink to="/" className="px-xl-4 px-3 navtext-color hover-blue-color f-bold" activeClassName="active">FAQ</RouterLink>
                        <RouterLink to="/" className="px-xl-4 px-3 navtext-color hover-blue-color f-bold" activeClassName="active">API</RouterLink> */}
                        {/* <NavItem path="/" exact={true} itemName="Blockchain" />
                        <NavItem path="/faq" exact={false} itemName="FAQ" />
                        <NavItem path="/api" exact={false} itemName="API" /> */}
                    </div>
                </div>
                {/* @ts-ignore */}
               {/*  <Dropdown isOpen={dropdownOpen} toggle={()=> setDropdownOpen(prevState => !prevState)} className="ps-xl-4 ps-3 f-16px f-medium dropdown-custom dropdown-blue">
                    <DropdownToggle caret >
                        {currentChannel}
                        
                    </DropdownToggle>
                    <DropdownMenu >
                        {
                            channelList.length > 0 && channelList.map((channel: string, index: number) => <DropdownItem key={index} 
                            disabled={((channel === "bStamp") ||(channel ===  "banji")) ? false : true}
                             onClick={() => handleChannelChange(channel)}
                             >{channel}</DropdownItem>)
                        }
                    </DropdownMenu>
                </Dropdown> */}
            </div>
        </div>
    )
}