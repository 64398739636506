export const defaultReceipt = "bStamp";
/* export const defaultReceipt = {
    bstamp:"bStamp"
} */
export const graphTimeFormat = "hh:mm A";
export const searchHashLengthValidation = 64;
export const dashboardGraphLabels = [
    { label: "Tx/Hour", value: "transactionByHour", combineNodes: 3 },
    { label: "Tx/Min", value: "transactionByMinute", combineNodes: 7 },
    { label: "Blocks/Hour", value: "blocksByHour", combineNodes: 3 },
    { label: "Blocks/Min", value: "blocksByMinute", combineNodes: 7 },
]

export const cookieKeys = {
    cryptoSecretKey: "edexaUser",
    cookieInitial: "edexa",
    cookieUser: "CookieUser",
};
export const localstorageKeys = {
    userInfo: "userInfo",
    userToken: "userToken",
    dashboardDataCounts: "dashboardDataCounts",
    graphValues: "graphValues"
};

export const toasterMessages = {
    comingSoon: "Coming Soon",
};

export const defaultCountryCode = "ch";

export const dateFormat = "DD-MM-YYYY | h:mm";
export const toasterPosition = "bottom-left";
export const modulesarray = [
    "Dashboard",
    "Holders",
    "Credential Schemas",
    "Issue Credential",
    "External Credential",
];
export const onlyCharacterValidationRegex = /^[aA-zZ '\s]+$/;
export const onlyNumberRegex = /^[0-9]*$/;

export const modulesArray = [
    { label: "Dashboard", path: "/" },
    { label: "Holders", path: "/holders" },
    { label: "Credential Schemas", path: "/credentials" },
    { label: "Issue Credential", path: "/issuecredential" },
    { label: "Verifiers", path: "/verifiers" },
];

export const allRoutesArray = [
    { label: "Dashboard", path: "/dashboard" },

    // listing Components
    { label: "API Management", path: "/api-management" },
    { label: "Application Management", path: "/application-management" },
    { label: "User Management", path: "/user-management" },
    { label: "KYC Management", path: "/kyc-management" },
];

export const rowsPerPage = [10, 20, 30, 40, 50];

export const initialPaginationConfig = {
    search: "",
    page: 1,
    limit: 10,
    filter: "",
};

export const debounceTimeInMilliseconds = 500;

export const validationMessages = {
    onlyAlphabets: "Please enter aplhabets only",
    onlyNumerical: "Please enter numerical value",
    emptySearch: "Please enter a hash",
    invalidHash: "Please enter valid transaction ID",
    connectionTimeOut: "Connection Timed Out"
};

export const rejectionReasons = [
    "Name in the Photo ID and the Name on the edeXa do not match",
    "When the date of birth in the Photo ID and the date of birth on the form do not match",
    "Country of issuing authority of the Photo ID is different from the nationality of the applicant",
    "Resolution of the picture is too low to confirm the information",
    "Other",
];

export const loginCred = {
    email: "exploreradmin",
    password: "exploreradminpw",

}
