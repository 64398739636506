import { getInitialDashBoardCounts } from "../../common/common-functions";
import { defaultReceipt } from "../../common/constants";
import { CHANNEL_COUNT, CHANNEL_HASH, CHANNEL_LIST, CURRENT_CHANNEL, SEARCH_HASH } from "./type";

const initialState = {
    channelList: [],
    currentChannel: defaultReceipt,
    channel_genesis_hash: "a7c8a6615070ab0bf1d8ef92ab675ae5aac0d8e47b080558bde168d0d8029142",
    searchHashResponse: null,
    channelCounts: getInitialDashBoardCounts().result.data
};

export const channelDetailsReducer = (state = initialState, action: { type: string, payload: any }) => {
    const newState = { ...state };

    switch (action.type) {
        case CHANNEL_LIST:
            newState.channelList = action.payload;
            break;

        case CURRENT_CHANNEL:
            newState.currentChannel = action.payload;

            break;

        case CHANNEL_HASH:
            newState.channel_genesis_hash = action.payload;
            break;

        case SEARCH_HASH:
            newState.searchHashResponse = action.payload;
            break;

        case CHANNEL_COUNT:
            newState.channelCounts = action.payload;
            break;

        default:
    }
    return newState;
}