import { NavLink as RouterLink } from 'react-router-dom';
import "../../assets/scss/layouts/footer.scss";
import { environment } from '../../environment';
export const Footer = () => {

    const redirectTo = (url: string) => {
        window.open(url, "_blank");
    }

    return (
        <div className="footer white-bg  p-3 darkgray-color f-14px">
            <div className="row">
                <div className="col-sm-6 mb-sm-0 mb-2">
                    <div className="copyright ">edeXa - Blockchain Explorer © {new Date().getFullYear()}</div>
                </div>
                <div className="col-sm-6">
                    <div className="social-links text-sm-end">
                        <RouterLink onClick={() => redirectTo(environment.privacy_policy)} to="" className="darkgray-color pe-xl-4 pe-3 hover-blue-color" activeClassName="active blue-color">Privacy</RouterLink>
                        <RouterLink onClick={() => redirectTo(environment.terms_conditions)} to="" className="darkgray-color pe-xl-4 pe-3 hover-blue-color" activeClassName="active blue-color">Terms</RouterLink>
                        {/* <RouterLink to="/help" className="darkgray-color pe-xl-4 pe-3 hover-blue-color" activeClassName="active blue-color">Help</RouterLink>
                        <RouterLink to="/about" className="darkgray-color pe-xl-4 pe-3 hover-blue-color" activeClassName="active blue-color">About</RouterLink> */}
                    </div>
                </div>
            </div>


        </div>
    )
}