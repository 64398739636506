import { Login } from "../Pages/Auth/Login";
import { Register } from "../Pages/Auth/register";
import { Dashboard } from "../Pages/Main/Dashboard/main";
import { Profile } from "../Pages/Main/Profile";
import { Search } from "../Pages/Main/search";

export const privateRoutes = [
    {
        path: "/",
        component: Dashboard,
    },
    {
        path: "/dashboard",
        component: Dashboard,
    },
    {
        path: "/profile",
        component: Profile,
    },
    {
        path: "/search",
        component: Search
    },
    {
        path: "/search/:txid",
        component: Search
    }
];

export const publicRoutes = [
    {
        path: "/",
        component: Login,
    },
    {
        path: "/login",
        component: Login,
    },
    {
        path: "/signup",
        component: Register,
    }
];
