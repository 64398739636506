import { Suspense, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import { Spinner } from "reactstrap";
import { onLogin } from "../actions/auth";
import { localstorageKeys } from "../common/constants";
import { ProtectedRouteComponent } from "./ProtectedRouteComponent";
// import { Page404 } from "../Pages/common/Page404";
import { privateRoutes } from "./routes";
import { useDispatch } from "react-redux";
import { onUserLogIn } from "../store/auth/action";
import { Toaster } from "react-hot-toast";

function RoutingComponent() {
  const { isLoggedIN } = useSelector((state: any) => state.auth);
  const { isLoading } = useSelector((state: any) => state.loading);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isLoggedIN) {
      // call login api
      onLogin().then((response: any) => {
        const userToken = `Bearer ${response.data.token}`;
        if (userToken) {
          localStorage.setItem(localstorageKeys.userToken, userToken);
          dispatch(onUserLogIn({ isLoggedIN: true, userToken: userToken }));
        }
      });
    }
  }, [dispatch, isLoggedIN]);

  return (
    <>
      {isLoading && (
        <div className="loader-wrapper">
          <Spinner type="grow" color="light" />
        </div>
      )}
      <Suspense fallback={<p>Loading...</p>}>
        <Toaster />
        <Router>
          {
            // isLoggedIN &&
            <ProtectedRouteComponent
              Route={Route}
              Redirect={Redirect}
              privateRoutes={privateRoutes}
              Switch={Switch}
            />
          }
        </Router>
      </Suspense>
    </>
  );
}

export default RoutingComponent;
