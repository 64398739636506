export const chartInitialState = {
    series: [
        {
            name: "",
            data: [],
        },
    ],
    chart: {
        height: 200,
        type: 'area',
        background: '#fff',
        toolbar: {
            show: false
        },
    },
    dataLabels: {
        enabled: false
    },
    stroke: {
        curve: 'smooth',
        width: 1
    },
    xaxis: {
        categories: []
    },
    fill: {
        type: "gradient",
        gradient: {
            shadeIntensity: 0.8,
            opacityFrom: 0.9,
            opacityTo: 0.7,
            stops: [0, 90, 100]
        }
    },
};