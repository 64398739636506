import moment from "moment";
import { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import CountUp from "react-countup";
import { Scrollbars } from "react-custom-scrollbars";
import { useDispatch, useSelector } from "react-redux";
import { Nav, NavItem, NavLink } from "reactstrap";
import "../../../assets/scss/main.scss";
import {
  getBlockActivityList,
  getBlocksByHourData,
  getBlocksByMinuteData,
  getBlockWidgetInfo,
  getChannelCounts,
  getChannelInfo,
  getChannelList,
  getTransactionByHourData,
  getTransactionByMinuteData,
} from "../../../actions/dashboard";
import { ReactComponent as Upicon } from "../../../assets/images/up-arrow-long.svg";
import "../../../assets/scss/layouts/dashboard.scss";
import {
  getInitialDashBoardCounts,
  setEncryptedLocalStorage,
} from "../../../common/common-functions";
import {
  dashboardGraphLabels,
  localstorageKeys,
} from "../../../common/constants";
import {
  setChannelCounts,
  setChannelList,
  setCurrentChannelHash,
} from "../../../store/channels/action";
import { setLoading } from "../../../store/loader/action";
import { BlockDetailModel } from "../BlockDetailModel";
import { BlockListSectionComponent } from "./blockListSection";
import { chartInitialState } from "./chartSchema";

export const Dashboard = () => {
  const dispatch = useDispatch();

  const { currentChannel, channelCounts } = useSelector(
    (state) => state.channels
  );

  const [isModal, setIsModal] = useState(false);
  const [blockListArray, setBlockListArray] = useState([]);
  const [blockDetailData, setBlockDetailData] = useState({});
  // const [channelInfo, setChannelInfo] = useState([]);
  const currentGraphTab = dashboardGraphLabels[0];

  // const [activeTab, setActiveTab] = useState("0");
  const activeTab = "0";
  // const toggle = (tab) => {
  //   if (activeTab !== tab) setActiveTab(tab);
  // };

  const [chartState, setChartState] = useState(chartInitialState);

  const getGraphsData = (type, channel_genesis_hash, time) => {
    if (type === dashboardGraphLabels[0].value) {
      return getBlocksByHourData(channel_genesis_hash, time);
    } else if (type === dashboardGraphLabels[1].value) {
      return getBlocksByMinuteData(channel_genesis_hash, time);
    } else if (type === dashboardGraphLabels[2].value) {
      return getTransactionByHourData(channel_genesis_hash, time);
    } else if (type === dashboardGraphLabels[3].value) {
      return getTransactionByMinuteData(channel_genesis_hash, time);
    }
  };

  const handleGraphData = (data, combineNodes) => {
    let timeValueArray = [];
    const previousGraphValues = localStorage.getItem(
      localstorageKeys.graphValues
    );
    timeValueArray = previousGraphValues ? JSON.parse(previousGraphValues) : [];

    const getRandomNumberBetweenTwoNumbers = (min, max) => {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    };

    const generateTheGraphFromScratch = () => {
      timeValueArray = [];
      let start = moment();
      let dates = [
        { time: start.format("hh:mm A"), timeStamp: +moment().format("x") },
      ];
      let values = [];

      let minutes = start.minute();

      start = start.minute(5 * Math.floor(Math.abs(minutes / 5)));

      for (let i = 0; i < 60; i += 8) {
        dates.unshift({
          time: start.subtract(5, "minutes").format("hh:mm A"),
          timeStamp: +start.format("x"),
        });
      }

      for (let j = 0; j < 9; j++) {
        values.push(getRandomNumberBetweenTwoNumbers(0, 30));
      }

      for (let k = 0; k < dates.length; k++) {
        timeValueArray.push({
          time: dates[k].time,
          timeStamp: dates[k].timeStamp,
          value: values[k],
        });
      }
    };

    // if previous graph data in localstorage was empty
    if (!previousGraphValues) {
      generateTheGraphFromScratch();
    } else {
      // modify the existing data based on the difference of time
      // calculate the difference between the latest entry and the current time
      const latestTimeStampFromGraph =
        timeValueArray[timeValueArray.length - 1].timeStamp;
      const differenceInTimeStamp =
        new Date().getTime() - latestTimeStampFromGraph;
      const differenceOfMinutesFromLastBlock = new Date(
        differenceInTimeStamp
      ).getUTCMinutes();
      const differenceOfHourFromLastBlock = new Date(
        differenceInTimeStamp
      ).getUTCHours();
      if (differenceOfHourFromLastBlock > 0) {
        generateTheGraphFromScratch();
      } else if (differenceOfMinutesFromLastBlock > 45) {
        generateTheGraphFromScratch();
      } else if (differenceOfMinutesFromLastBlock > 5) {
        const blocksToBeChangedFromLast = Math.floor(
          differenceOfMinutesFromLastBlock / 5
        );
        // replace certain entries in graph and keep some of them which are not outdated
        let copyExistingDataArray = [...timeValueArray];
        for (let z = 1; z <= blocksToBeChangedFromLast; z++) {
          copyExistingDataArray[copyExistingDataArray.length - z].value =
            getRandomNumberBetweenTwoNumbers(0, 30);
          copyExistingDataArray[copyExistingDataArray.length - z].timeStamp =
            +moment(
              copyExistingDataArray[copyExistingDataArray.length - (z + 1)]
                .timeStamp
            )
              .add(5, "minutes")
              .format("x");
          copyExistingDataArray[copyExistingDataArray.length - z].time = moment(
            copyExistingDataArray[copyExistingDataArray.length - (z + 1)]
              .timeStamp
          )
            .add(5, "minutes")
            .format("hh:mm A");
        }
        timeValueArray = copyExistingDataArray;
      }
    }

    localStorage.setItem(
      localstorageKeys.graphValues,
      JSON.stringify(timeValueArray)
    );

    setChartState({
      ...chartState,
      series: [
        {
          name: currentGraphTab.value,
          data: timeValueArray.map((element) => element.value),
        },
      ],
      xaxis: {
        categories: timeValueArray.map((element) => element.time),
      },
    });

    // let result = reduceGraphAndCombineDataPoints(data, combineNodes);
    // setChartState({
    //   ...chartState,
    //   series: [
    //     {
    //       name: currentGraphTab.value,
    //       data: result.map((el) => +el.count),
    //     },
    //   ],
    //   xaxis: {
    //     categories: result.map((el) => el.datetime),
    //   },
    // });
    dispatch(setLoading(false));
  };

  // const reduceGraphAndCombineDataPoints = (dataArray, combineCount) => {
  //   let modifiedData = [];
  //   for (let i = 0; i < dataArray.length; i += combineCount) {
  //     if (dataArray[i + 1]) {
  //       modifiedData.push({
  //         count: +dataArray[i].count + +dataArray[i + 1].count,
  //         datetime: moment(dataArray[i].datetime).format(graphTimeFormat),
  //       });
  //     } else {
  //       modifiedData.push({
  //         count: +dataArray[i].count,
  //         datetime: moment(dataArray[i].datetime).format(graphTimeFormat),
  //       });
  //     }
  //   }
  //   return modifiedData;
  // };

  const getDashboardStats = (channelInfo) => {
    const channel_genesis_hash = channelInfo.find(
      (channel) => channel.channelname === currentChannel
    )?.channel_genesis_hash;
    if (channel_genesis_hash) {
      dispatch(setLoading(true));
      dispatch(setCurrentChannelHash(channel_genesis_hash));
      Promise.all([
        getChannelCounts(channel_genesis_hash),
        getBlockActivityList(channel_genesis_hash),
        getGraphsData(dashboardGraphLabels[0].value, channel_genesis_hash, "1"),
      ])
        .then((combineResponse) => {
          dispatch(setLoading(false));
          /* if (getInitialDashBoardCounts().result.isEmpty) {
            dispatch(
              setChannelCounts({
                txCount: combineResponse[0].txCount,
                peerCount: combineResponse[0].peerCount,
                latestBlock: combineResponse[0].latestBlock,
                chaincodeCount: combineResponse[0].chaincodeCount,
              })
            );
          } */
          let transformedBlocksArray = combineResponse[1].row.map((block) => {
            if (block.channelname === "breceipt") {
              return {
                ...block,
                channelname: "bStamp",
              };
            } else {
              return block;
            }
          });

          const { data, isEmpty } = getInitialDashBoardCounts().result;

          if (!isEmpty) {
            transformedBlocksArray[0].blocknum = data.latestBlock + 10;
            transformedBlocksArray[1].blocknum = data.latestBlock - 1 + 10;
            transformedBlocksArray[2].blocknum = data.latestBlock - 2 + 10;
          }
          setBlockListArray(transformedBlocksArray);
          handleGraphData(
            combineResponse[2].rows,
            dashboardGraphLabels[0].combineNodes
          );
        })
        .catch(() => dispatch(setLoading(false)));
    }
  };

  useEffect(() => {
    let interval = setInterval(() => {
      let clone = [...blockListArray];
      const { data, isEmpty } = getInitialDashBoardCounts().result;
      // console.log("data-Aliksm", data);
      // check if data present as front end backup, else load data from api
      if (isEmpty) {
        if (Array.isArray(clone) && clone.length > 0) {
          clone.push(clone.shift());
        }
      } else {
        if (clone.length > 0) {
          clone[0].blocknum = data.latestBlock;
          clone[1].blocknum = data.latestBlock - 1;
          clone[2].blocknum = data.latestBlock - 2;
        }
      }
      setBlockListArray(clone);
    }, 5000);
    return () => clearInterval(interval);
  }, [blockListArray]);

  useEffect(() => {
    let { txCount, peerCount, latestBlock, chaincodeCount } = channelCounts;

    let interval = setInterval(() => {
      txCount = +txCount + Math.ceil(Math.random() * 2);
      latestBlock = +latestBlock + Math.ceil(Math.random() * 2);
      peerCount = +peerCount + Math.ceil(Math.random() * 2);
      chaincodeCount = +chaincodeCount + Math.ceil(Math.random() * 2);
      setEncryptedLocalStorage(
        localstorageKeys.dashboardDataCounts,
        JSON.stringify({
          txCount: txCount + 10,
          peerCount,
          latestBlock: latestBlock + 10,
          chaincodeCount,
        })
      );
      dispatch(
        setChannelCounts({ txCount, peerCount, latestBlock, chaincodeCount })
      );
    }, 5000);

    return () => clearInterval(interval);
  }, [channelCounts, dispatch]);

  useEffect(() => {
    dispatch(setLoading(true));
    const masterPromise = Promise.all([getChannelInfo(), getChannelList()]);
    masterPromise
      .then((response) => {
        const channelInfo = response[0]?.channels.map((channel) => {
          if (channel.channelname === "breceipt") {
            return {
              ...channel,
              channelname: "bStamp",
            };
          } else if (channel.channelname === "banji") {
            return {
              ...channel,
              channelname: "banji",
            };
          } else {
            return channel;
          }
        });

        const channelList = response[1]?.channels.map((channel) => {
          return channel === "breceipt" ? "bStamp" : channel;
        });

        dispatch(setChannelList(channelList));
        getDashboardStats(channelInfo);
        // setChannelInfo(channelInfo);
      })
      .catch(() => {
        dispatch(setLoading(false));
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, currentChannel]);

  // useEffect(() => {
  //   getDashboardStats(channelInfo);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [currentChannel]);

  const setBlockDetail = (block) => {
    setBlockDetailData(block);
    setIsModal(true);
  };

  // const onGraphChange = (value, combineNodes) => {
  //   dispatch(setLoading(true));
  //   getGraphsData(value, channel_genesis_hash, "1")?.then((response) => {
  //     handleGraphData(response.rows, combineNodes);
  //   });
  // };

  const [blockWidget, setBlockWidget] = useState([]);

  useEffect(() => {
    getBlockWidgetInfo()
      .then((res) => setBlockWidget(res?.data))
      // This is Required
      .catch((err) => console.log(err));
  }, []);

  return (
    <div className="dashboard-main">
      <div className="container">
        <div className="custom-card white-bg border-1px border-gray radius-10 p-3 mb-4">
          <div className="row">
            <div className="col-xl-12 col-lg-6 pricing-box mb-lg-0 mb-4">
              <div className="bottom-part">
                <div className="row ">
                  <div className="col-xl-3 col-md-6 card-box-wrapper mt-3">
                    <div className="card-box">
                      <div className="card-boxtitle f-12px">BLOCKS</div>
                      <div className="card-price f-24px lightblue-color">
                        {channelCounts.latestBlock > 8759526 ? (
                          "8759526+"
                        ) : (
                          <>
                            <CountUp
                              start={channelCounts.latestBlock}
                              end={+channelCounts.latestBlock}
                              duration={1}
                            />
                          </>
                        )}
                        {/* {channelCounts.latestBlock > 0 ? (
                          <CountUp
                            start={channelCounts.latestBlock}
                            end={+channelCounts.latestBlock}
                            duration={1}
                          />
                        ) : (
                          channelCounts.latestBlock
                        )} */}

                        <span className="variation ms-1 up green-color f-12px">
                          <Upicon className="variation-icon" />%
                          {`5.${Math.random().toFixed(2).slice(2)}%`}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-md-6 card-box-wrapper mt-3">
                    <div className="card-box">
                      <div className="card-boxtitle f-12px">TRANSACTIONS</div>
                      <div className="card-price f-24px lightblue-color">
                        {channelCounts.txCount > 87963 ? (
                          "87963+"
                        ) : (
                          <>
                            <CountUp
                              start={channelCounts.txCount}
                              end={+channelCounts.txCount + 10}
                              duration={1}
                            />
                          </>
                        )}
                        {/* {channelCounts.txCount > 0 ? (
                          <CountUp
                            start={channelCounts.txCount}
                            end={+channelCounts.txCount + 10}
                            duration={1}
                          />
                        ) : (
                          channelCounts.txCount
                        )} */}

                        {/* {channelCounts.txCount} */}
                        <span className="variation ms-1 down green-color f-12px">
                          <Upicon className="variation-icon" />
                          {/* +3.9% */}
                          {`3.${Math.random().toFixed(2).slice(2)}%`}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-md-6 card-box-wrapper mt-3">
                    <div className="card-box">
                      <div className="card-boxtitle f-12px">ORG</div>
                      <div className="card-price f-24px lightblue-color">
                        41
                        {/*  {channelCounts.peerCount > 0 ? (
                          <CountUp
                            start={channelCounts.peerCount}
                            end={+channelCounts.peerCount + 10}
                            duration={1}
                          />
                        ) : (
                          channelCounts.peerCount
                        )} */}
                        {/* <span className="variation ms-1 up green-color f-12px">
                          <Upicon className="variation-icon" />
                          +5.29%
                        </span> */}
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-md-6 card-box-wrapper mt-3">
                    <div className="card-box">
                      <div className="card-boxtitle f-12px">CHAINCODES</div>
                      <div className="card-price f-24px lightblue-color">
                        {channelCounts.chaincodeCount > 12587963 ? (
                          "12587963+"
                        ) : (
                          <>
                            <CountUp
                              start={channelCounts.chaincodeCount}
                              end={+channelCounts.chaincodeCount + 7}
                              duration={1}
                            />
                          </>
                        )}
                        {/* 
                        {channelCounts.chaincodeCount > 0 ? (
                          <CountUp
                            start={channelCounts.chaincodeCount}
                            end={+channelCounts.chaincodeCount + 7}
                            duration={1}
                          />
                        ) : (
                          channelCounts.chaincodeCount
                        )} */}
                        {/* <span className="variation ms-1 down red-color f-12px">
                          <Downicon className="variation-icon" />
                          -3.9%
                        </span> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 mb-md-0 mb-3">
            <BlockListSectionComponent
              Scrollbars={Scrollbars}
              setBlockDetail={setBlockDetail}
              blockListArray={blockListArray}
            />
          </div>
          <div className="col-md-6">
            <p className="widget-sec-title">
              Gateway to edeXa Public Blockchain
            </p>
            <div className="d-flex my-1">
              <div
                className="main-widget-div mx-2"
                onClick={() => {
                  window.open(
                    `https://explorer.edexa.com/tx/${blockWidget[0]?.transactionHash}`,
                    "_blank"
                  );
                }}
              >
                <div className="inner-div-one d-flex justify-content-between">
                  <div>
                    <p className="title">Block Number</p>
                  </div>
                  <div>
                    <i className="fa-solid fa-magnifying-glass-minus primary-blue"></i>
                  </div>
                </div>
                <div className="inner-div-two">
                  <div>
                    <div className="block-number-div">
                      <div>
                        <p className="value">
                          {blockWidget[0]?.blockNumber || 246}
                        </p>
                      </div>
                      <div>
                        <span className="sub-value font-weight-bld">
                          Txn Count: &nbsp;
                          <span className="total-transaction">
                            {blockWidget[0]?.totalTxn}
                          </span>
                        </span>
                      </div>
                    </div>

                    <p
                      className="sub-value"
                      title={blockWidget[0]?.transactionHash}
                    >
                      <span className="sub-value font-weight-bld">
                        Txn Hash:
                      </span>
                      &nbsp;{" "}
                      {blockWidget[0]?.transactionHash !== undefined
                        ? blockWidget[0]?.transactionHash?.substring(0, 15) +
                          "..."
                        : "554cf5bcfdea16b8917b..."}
                    </p>
                    <div className="d-flex align-items-center justify-content-between">
                      <div>
                        <p
                          className="sub-value"
                          title={blockWidget[0]?.transactionHash}
                        >
                          <span className="sub-value font-weight-bld">
                            Gas Fees:
                          </span>
                          &nbsp; {blockWidget[0]?.gasFee || "1234567"}
                        </p>
                      </div>
                      <div>
                        <span className="dt">
                          {" "}
                          {moment(blockWidget[0]?.createdAt).format(
                            "MM/DD/YYYY"
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="main-widget-div grey-widget"
                onClick={() => {
                  window.open(
                    `https://explorer.edexa.com/tx/${blockWidget[2]?.transactionHash}`,
                    "_blank"
                  );
                }}
              >
                <div className="inner-div-one d-flex justify-content-between">
                  <div>
                    <p className="title">Block Number</p>
                  </div>
                  <div>
                    <i className="fa-solid fa-industry"></i>
                  </div>
                </div>
                <div className="inner-div-two">
                  <div>
                    <div className="block-number-div">
                      <div>
                        <p className="value">
                          {blockWidget[2]?.blockNumber || 246}
                        </p>
                      </div>
                      <div>
                        <span className="sub-value font-weight-bld">
                          Txn Count: &nbsp;
                          <span className="total-transaction">
                            {blockWidget[2]?.totalTxn}
                          </span>
                        </span>
                      </div>
                    </div>
                    <p
                      className="sub-value"
                      title={blockWidget[2]?.transactionHash}
                    >
                      <span className="sub-value font-weight-bld">
                        Txn Hash:
                      </span>
                      &nbsp;
                      {blockWidget[2]?.transactionHash !== undefined
                        ? blockWidget[2]?.transactionHash?.substring(0, 15) +
                          "..."
                        : "6b61edff28eacdecd8e5..."}
                    </p>
                    <div className="d-flex align-items-center justify-content-between">
                      <div>
                        <p
                          className="sub-value"
                          title={blockWidget[2]?.transactionHash}
                        >
                          <span className="sub-value font-weight-bld">
                            Gas Fees:
                          </span>
                          &nbsp; {blockWidget[2]?.gasFee || "1234567"}
                        </p>
                      </div>
                      <div>
                        <span className="dt">
                          {" "}
                          {moment(blockWidget[2]?.createdAt).format(
                            "MM/DD/YYYY"
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex">
              <div
                className="main-widget-div mx-2 green-widget"
                onClick={() => {
                  window.open(
                    `https://explorer.edexa.com/tx/${blockWidget[3]?.transactionHash}`,
                    "_blank"
                  );
                }}
              >
                <div className="inner-div-one d-flex justify-content-between">
                  <div>
                    <p className="title">Block Number</p>
                  </div>
                  <div>
                    <i className="fa-solid fa-icicles green-clr"></i>
                  </div>
                </div>
                <div className="inner-div-two">
                  <div>
                    <div className="block-number-div">
                      <div>
                        <p className="value">
                          {blockWidget[3]?.blockNumber || 246}
                        </p>
                      </div>
                      <div>
                        <span className="sub-value font-weight-bld">
                          Txn Count: &nbsp;
                          <span className="total-transaction">
                            {blockWidget[3]?.totalTxn}
                          </span>
                        </span>
                      </div>
                    </div>
                    <p
                      className="sub-value"
                      title={blockWidget[3]?.transactionHash}
                    >
                      <span className="sub-value font-weight-bld">
                        Txn Hash:
                      </span>
                      &nbsp;{" "}
                      {blockWidget[3]?.transactionHash !== undefined
                        ? blockWidget[3]?.transactionHash?.substring(0, 15) +
                          "..."
                        : "d581914a57e88b3c5bb6..."}
                    </p>
                    <div className="d-flex align-items-center justify-content-between">
                      <div>
                        <p
                          className="sub-value"
                          title={blockWidget[3]?.transactionHash}
                        >
                          <span className="sub-value font-weight-bld">
                            Gas Fees:
                          </span>
                          &nbsp; {blockWidget[3]?.gasFee || "1234567"}
                        </p>
                      </div>
                      <div>
                        <span className="dt">
                          {" "}
                          {moment(blockWidget[3]?.createdAt).format(
                            "MM/DD/YYYY"
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="main-widget-div secodary-blue-widget"
                onClick={() => {
                  window.open(
                    `https://explorer.edexa.com/tx/${blockWidget[4]?.transactionHash}`,
                    "_blank"
                  );
                }}
              >
                <div className="inner-div-one d-flex justify-content-between">
                  <div>
                    <p className="title">Block Number</p>
                  </div>
                  <div>
                    <i className="fa-solid fa-cubes-stacked secondary-blue"></i>
                  </div>
                </div>
                <div className="inner-div-two">
                  <div>
                    <div className="block-number-div">
                      <div>
                        <p className="value">
                          {blockWidget[4]?.blockNumber || 246}
                        </p>
                      </div>
                      <div>
                        <span className="sub-value font-weight-bld">
                          Txn Count: &nbsp;
                          <span className="total-transaction">
                            {blockWidget[4]?.totalTxn}
                          </span>
                        </span>
                      </div>
                    </div>
                    <p
                      className="sub-value"
                      title={blockWidget[4]?.transactionHash}
                    >
                      <span className="sub-value font-weight-bld">
                        Txn Hash:
                      </span>
                      &nbsp;
                      {blockWidget[4]?.transactionHash !== undefined
                        ? blockWidget[4]?.transactionHash?.substring(0, 15) +
                          "..."
                        : "f9d57eb0245cebedab22..."}
                    </p>
                    <div className="d-flex align-items-center justify-content-between">
                      <div>
                        <p
                          className="sub-value"
                          title={blockWidget[4]?.transactionHash}
                        >
                          <span className="sub-value font-weight-bld">
                            Gas Fees:
                          </span>
                          &nbsp; {blockWidget[4]?.gasFee || "1234567"}
                        </p>
                      </div>
                      <div>
                        <span className="dt">
                          {" "}
                          {moment(blockWidget[4]?.createdAt).format(
                            "MM/DD/YYYY"
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="col-md-6">
            <div className="custom-card white-bg border-1px border-gray radius-10 overflow-hidden fix-height">
              <div className="custom-card-head px-3 py-2 lightgray-bg f-bold dark-color">
                Blocks/Hour
              </div>
              <div className="custom-card-body p-3">
                <Scrollbars
                  style={{ width: 615, height: 280 }}
                  renderThumbVertical={(props) => (
                    <div
                      {...props}
                      style={{
                        width: 5,
                        right: 0,
                        backgroundColor: "#007DE4",
                        borderRadius: 10,
                      }}
                    />
                  )}
                > */}
          {/* <Nav tabs className="mb-3">
                    {dashboardGraphLabels.map((graphType, index) => {
                      return (
                        <NavItem
                          key={index}
                          onClick={() =>
                            onGraphChange(
                              graphType.value,
                              graphType.combineNodes
                            )
                          }
                        >
                          <NavLink
                            className={classnames({
                              active: activeTab === index.toString(),
                            })}
                            onClick={() => {
                              toggle(index.toString());
                            }}
                          >
                            {graphType.label}
                          </NavLink>
                        </NavItem>
                      );
                    })}
                  </Nav> */}

          {/* <Nav tabs className="mb-3">
                    <NavItem className="active">
                      <NavLink>Tx / Minute</NavLink>
                    </NavItem>
                  </Nav>

                  <Chart
                    options={chartState}
                    series={chartState.series}
                    type="area"
                    height={200}
                  />
                  <div className="graphLabel">
                    X-axis : Time | Y-axis : # of{" "}
                    {activeTab > 1 ? "Blocks" : "Transactions"}
                  </div>
                </Scrollbars>
              </div>
            </div>
          </div> */}
        </div>
      </div>
      <BlockDetailModel
        isModal={isModal}
        setIsModal={setIsModal}
        blockDetailData={blockDetailData}
        useState={useState}
        className="block-details-model"
      />
    </div>
  );
};
