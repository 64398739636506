import { combineReducers } from "redux";
import { authReducer } from "./auth/reducer";
import { channelDetailsReducer } from "./channels/reducer";
import { loadingReducer } from "./loader/reducer";

const rootReducer = combineReducers({
    auth: authReducer,
    loading: loadingReducer,
    channels: channelDetailsReducer
});

export default rootReducer;

