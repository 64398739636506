import { ISLOGGEDIN, LOGINAFTERTOKENEXPIRY, LOGINUSER, ONLOGOUT } from "./type";

export const onUserLogIn = (payload: { isLoggedIN: boolean, userToken: string }) => {
    return { type: ISLOGGEDIN, payload };
}

export const onUserLogout = (payload: boolean) => {
    return { type: ONLOGOUT, payload };
}

export const loginUser = (token: string) => {
    return { type: LOGINUSER, payload: token }
}

export const onLoginAfterTokenExpiry = () => {
    return { type: LOGINAFTERTOKENEXPIRY }
}