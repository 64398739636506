import { useEffect, useState } from "react";
import { getBlockTableInfo } from "../../../actions/dashboard";
import LinkIcon from "../../../assets/images/link.png";

export const BlockListSectionComponent = ({
  Scrollbars,
  setBlockDetail,
  blockListArray,
}: {
  Scrollbars: any;
  setBlockDetail: any;
  blockListArray: any[];
}) => {
  const [blockTableData, setBlockTableData] = useState([]);

  const getBlockTableInfoFunction = () => {
    getBlockTableInfo()
      .then((res: any) => setBlockTableData(res.data.data))
      // This is Required
      .catch((err: any) => console.log(err));
  };

  useEffect(() => {
    const interval = setInterval(() => {
      getBlockTableInfoFunction();
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="custom-card white-bg border-1px border-gray radius-10 overflow-hidden fix-height">
      <div className="custom-card-head px-3 py-2 lightgray-bg f-bold dark-color">
        Block Table
      </div>
      <div className="custom-card-body p-3">
        <Scrollbars
          style={{ width: 615, height: 280, zIndex: 2 }}
          renderThumbVertical={(props: any) => (
            <div
              {...props}
              style={{
                width: 5,
                right: 0,
                backgroundColor: "#007DE4",
                borderRadius: 10,
              }}
            />
          )}
        >
          {/* {blockListArray.length > 0 ? (
            blockListArray.map((block) => { */}
          {blockTableData?.length > 0 ? (
            blockTableData?.map((block: any, index: number) => {
              return (
                <div
                  className="custom-card-block border-1px border-gray radius-10 f-14px overflow-hidden"
                  key={index}
                >
                  <div className="block-head maya-bg py-2 px-3 f-bold d-flex flex-wrap align-items-center justify-content-between">
                    <div className="title">Block {block?.blockNumber}</div>
                    <div
                      className="dark-color cursor-pointer d-flex"
                      // onClick={() => setBlockDetail(block)}
                    >
                      <div className="block-link">
                        {/* <img
                          src={LinkIcon}
                          width="100%"
                          height="100%"
                          alt={"link-icon"}
                        /> */}
                      </div>
                      <div className="dark-color cursor-pointer hover-blue-color">
                        {/* Block link */}
                      </div>
                    </div>
                  </div>
                  <div className="block-body py-2 px-3 text-break white-bg">
                    <div className="block-row darkgray-color d-flex">
                      {/* <span className="block-label f-bold text-nowrap me-1 darktext-color">Channel name :</span> {block?.channelname} */}
                      <span className="block-label f-bold text-nowrap me-1 darktext-color">
                        Channel name :
                      </span>{" "}
                      {block?.channelName}
                    </div>
                    <div className="block-row darkgray-color d-flex">
                      <span className="block-label f-bold text-nowrap me-1 darktext-color">
                        Datahash :
                      </span>{" "}
                      {block?.dashHash}
                    </div>
                    <div className="block-row darkgray-color d-flex">
                      <span className="block-label f-bold text-nowrap me-1 darktext-color">
                        Number of Txn. :
                      </span>{" "}
                      {block?.totaltransaction}
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div>No Records</div>
          )}
        </Scrollbars>
        {/* {blockListArray?.length > 0 && ( */}
        {blockTableData?.length > 0 && (
          <div className="block-bg-animate-wrap">
            <div>
              <div className="slider-move"></div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
