import classnames from 'classnames';
import moment from "moment";
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink as RouterLink, useParams } from 'react-router-dom';
import { Nav, NavItem, NavLink, TabContent, Table, TabPane } from 'reactstrap';
import { searchTransaction } from '../../actions/dashboard';
import "../../assets/scss/layouts/search-result.scss";
import "../../assets/scss/reactstrap-override/tabs.scss";
import { dispatchSearchResult } from '../../store/channels/action';
import { setLoading } from '../../store/loader/action';


export const Search = () => {
    const [activeTab, setActiveTab] = useState('1');
    const { searchHashResponse, channel_genesis_hash } = useSelector((state: any) => state.channels);

    const params: any = useParams();
    const dispatch = useDispatch();

    const toggle = (tab: any) => {
        if (activeTab !== tab) setActiveTab(tab);
    }

    useEffect(() => {
        if (params?.txid) {
            dispatch(setLoading(true));
            searchTransaction(channel_genesis_hash, params.txid).then(response => {
                dispatch(setLoading(false));
                if (response.status === 200) {
                    dispatch(dispatchSearchResult(response.row));
                }
            });
        }
    }, [channel_genesis_hash, dispatch, params.txid]);

    return (
        <div className="search-result">
            <div className="container">
                <div className="custom-card white-bg border-1px border-gray radius-10 mb-4">
                    <div className="card-topbar p-3">
                        <div className="custom-card-title dark-color f-20px f-bold my-2">Details for Transaction</div>
                        <p className="card-description f-14px darkgray-color">Some info may be visible to other people using Google services. <RouterLink to="/" className="blue-color">Learn more</RouterLink></p>
                    </div>
                    <div className="custom-table text-break">
                        <div className="table-row border-top-1px border-gray p-3 d-flex flex-wrap align-items-center">
                            <div className="label f-12px mb-md-0 mb-1 d-md-inline-block d-block">TRANSACTION ID</div>
                            <div className="detail f-bold ps-md-2">{searchHashResponse?.txhash}</div>
                        </div>
                        {
                            <div className="table-row border-top-1px border-gray p-3 d-flex flex-wrap align-items-center">
                                <div className="label f-12px mb-md-0 mb-1 d-md-inline-block d-block">BLOCK HEIGHT</div>
                                {
                                    searchHashResponse && <div className="detail f-bold ps-md-2"><RouterLink to="/" className="blue-color">{searchHashResponse?.read_set[1]?.set[0].version.block_num.low}</RouterLink></div>
                                }
                            </div>
                        }
                        <div className="table-row border-top-1px border-gray p-3 d-flex flex-wrap align-items-center">
                            <div className="label f-12px mb-md-0 mb-1 d-md-inline-block d-block">BLOCK DATE/TIME</div>
                            <div className="detail f-bold ps-md-2">{moment(searchHashResponse?.createdt).format("DD-MM-YYYY | hh:mm A")}</div>
                        </div>
                        <div className="table-row border-top-1px border-gray p-3 d-flex flex-wrap align-items-center">
                            <div className="label f-12px mb-md-0 mb-1 d-md-inline-block d-block">TRANSACTION PROPOSER</div>
                            <div className="detail f-bold ps-md-2" style={{
                                color: "transparent",
                                textShadow: "0 0 8px #000"
                            }}>
                                {/* {searchHashResponse?.creator_msp_id} */}
                                Name Protected
                            </div>
                        </div>
                        <div className="table-row border-top-1px border-gray p-3 d-flex flex-wrap align-items-center">
                            <div className="label f-12px mb-md-0 mb-1 d-md-inline-block d-block">VALIDATION CODE</div>
                            <div className="detail f-bold ps-md-2" style={{ color: "green" }}>{searchHashResponse?.validation_code}</div>
                        </div>
                    </div>
                </div>

                <div className="tab-layout">
                    <Nav tabs className="f-bold mb-3">
                        {/* <NavItem>
                            <NavLink
                                className={classnames({ active: activeTab === '1' })}
                                onClick={() => { toggle('1'); }}
                            >
                                Inputs/Outputs
                            </NavLink>
                        </NavItem> */}
                        <NavItem>
                            <NavLink
                                className={classnames({ active: activeTab === '2' })}
                                onClick={() => { toggle('2'); }}
                            >
                                Raw Transaction
                            </NavLink>
                        </NavItem>
                    </Nav>
                    <TabContent activeTab={activeTab}>
                        <TabPane tabId="1">
                            <div className="custom-card white-bg border-1px border-gray p-3 mb-3 radius-10">
                                <div className="custom-card-title dark-color f-20px f-bold pb-md-3 pb-2 mb-2 ">Payload</div>
                                <div className="data-table">
                                    <Table borderless size="sm" className="dark-color mb-0 text-break">
                                        <thead>
                                            <tr className="border-top-1px border-gray">
                                                <th>Index</th>
                                                <th>Previous output</th>
                                                <th>Payload Hash</th>
                                                {/* <th>Amount</th> */}
                                            </tr>
                                        </thead>
                                        <tbody className="darkgray-color">
                                            <tr className="border-top-1px border-gray">
                                                <td>0</td>
                                                {
                                                    searchHashResponse && <td><RouterLink to="/" className="blue-color">{searchHashResponse?.read_set[1].set[0].version.block_num.low}</RouterLink></td>
                                                }
                                                <td><RouterLink to="/" className="blue-color">{searchHashResponse?.payload_proposal_hash}</RouterLink></td>
                                                {/* <td>0.0001 DASH</td> */}
                                            </tr>
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                            {/* <div className="custom-card white-bg border-1px border-gray p-3 radius-10">
                                <div className="custom-card-title dark-color f-20px f-bold pb-md-3 pb-2 mb-2 ">Outputs</div>
                                <div className="data-table">
                                    <Table borderless size="sm" className="dark-color mb-0 text-break">
                                        <thead>
                                            <tr className="border-top-1px border-gray">
                                                <th>Index</th>
                                                <th>Previous output</th>
                                                <th>Address</th>
                                                <th>Amount</th>
                                            </tr>
                                        </thead>
                                        <tbody className="darkgray-color">
                                            <tr className="border-top-1px border-gray">
                                                <td>0</td>
                                                <td>Not yet redeemed</td>
                                                <td>OP_RETURN<RouterLink to="/" className="ms-2 blue-color copier-link f-12px radius-5 blueoverlay-bg px-1">.C..)....S...-..n...1 .Z.......,</RouterLink>
                                                    <div className="d-block">
                                                        <RouterLink to="/" className="blue-color copier-link f-12px radius-5 blueoverlay-bg px-1">6a208443b7c0298e0fb3c1538490de2d82166e189dbf31201d5aaef42ebf18a7ce2c</RouterLink>
                                                    </div>
                                                </td>
                                                <td>0.0 DASH</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </div>
                            </div> */}

                        </TabPane>
                        {/* <TabPane tabId="2">
                            <div className="custom-card white-bg border-1px border-gray p-3 mb-3 radius-10">
                                <div className="custom-card-title dark-color f-20px f-bold pb-md-3 pb-2 mb-2 ">Inputs second</div>
                                <div className="data-table">
                                    <Table borderless size="sm" className="dark-color mb-0 text-break">
                                        <thead>
                                            <tr className="border-top-1px border-gray">
                                                <th>Index</th>
                                                <th>Block Number</th>
                                                <th>Payload Hash</th>
                                                <th>Amount</th>
                                            </tr>
                                        </thead>
                                        <tbody className="darkgray-color">
                                            <tr className="border-top-1px border-gray">
                                                <td>0</td>
                                                <td><RouterLink to="/" className="blue-color">{searchHashResponse.read_set[1].set[0].version.block_num.low}</RouterLink></td>
                                                <td><RouterLink to="/" className="blue-color">6a208443b7c0298e0fb3c1538490de2d82166e189dbf31201d5aaef42ebf18a7ce2c</RouterLink></td>
                                                <td>0.0001 DASH</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                            <div className="custom-card white-bg border-1px border-gray p-3 radius-10">
                                <div className="custom-card-title dark-color f-20px f-bold pb-md-3 pb-2 mb-2 ">Outputs second</div>
                                <div className="data-table">
                                    <Table borderless size="sm" className="dark-color mb-0 text-break">
                                        <thead>
                                            <tr className="border-top-1px border-gray">
                                                <th>Index</th>
                                                <th>Previous output</th>
                                                <th>Address</th>
                                                <th>Amount</th>
                                            </tr>
                                        </thead>
                                        <tbody className="darkgray-color">
                                            <tr className="border-top-1px border-gray">
                                                <td>0</td>
                                                <td>Not yet redeemed</td>
                                                <td>OP_RETURN<RouterLink to="/" className="ms-2 blue-color copier-link f-12px radius-5 blueoverlay-bg px-1">.C..)....S...-..n...1 .Z.......,</RouterLink>
                                                    <div className="d-block">
                                                        <RouterLink to="/" className="blue-color copier-link f-12px radius-5 blueoverlay-bg px-1">6a208443b7c0298e0fb3c1538490de2d82166e189dbf31201d5aaef42ebf18a7ce2c</RouterLink>
                                                    </div>
                                                </td>
                                                <td>0.0 DASH</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                        </TabPane> */}
                    </TabContent>
                </div>
            </div>
        </div>
    )
}