export const loginAPI = "auth/login";
export const channelInfoAPI = "channels/info";
export const channelListAPI = "channels";
export const channelCountsAPI = "status";
export const blockActivityAPI = "blockActivity";
export const transactionPerHourAPI = "txByHour";
export const transactionPerMinuteAPI = "txByMinute";
export const blockByHourAPI = "blocksByHour";
export const blockByMinuteAPI = "blocksByMinute";
export const searchTransactionAPI = "transaction";
export const widgetBlocksApi = "block-hash";
export const tableBlocksApi = "hlf/transactions?limit=10&page=1";
