import axios from "axios";
import {
  blockActivityAPI,
  blockByHourAPI,
  blockByMinuteAPI,
  channelCountsAPI,
  channelInfoAPI,
  channelListAPI,
  searchTransactionAPI,
  tableBlocksApi,
  transactionPerHourAPI,
  transactionPerMinuteAPI,
  widgetBlocksApi,
} from "../common/api-endpoints";
import HTTPService from "../common/httpservice";
import { environment } from "../environment";

export const getChannelInfo = () => {
  return HTTPService.get(channelInfoAPI);
};

export const getChannelList = () => {
  return HTTPService.get(channelListAPI);
};

export const getChannelCounts = (channel_genesis_hash) => {
  return HTTPService.get(channelCountsAPI + `/${channel_genesis_hash}`);
};

export const getBlockActivityList = (channel_genesis_hash) => {
  return HTTPService.get(blockActivityAPI + `/${channel_genesis_hash}`);
};

export const getBlocksByHourData = (channel_genesis_hash, hours) => {
  return HTTPService.get(blockByHourAPI + `/${channel_genesis_hash}/${hours}`);
};

export const getBlocksByMinuteData = (channel_genesis_hash, minute) => {
  return HTTPService.get(
    blockByMinuteAPI + `/${channel_genesis_hash}/${minute}`
  );
};

export const getTransactionByHourData = (channel_genesis_hash, hours) => {
  return HTTPService.get(
    transactionPerHourAPI + `/${channel_genesis_hash}/${hours}`
  );
};

export const getTransactionByMinuteData = (channel_genesis_hash, minutes) => {
  return HTTPService.get(
    transactionPerMinuteAPI + `/${channel_genesis_hash}/${minutes}`
  );
};

export const searchTransaction = (channel_genesis_hash, txid) => {
  return HTTPService.get(
    searchTransactionAPI + `/${channel_genesis_hash}/${txid}`
  );
};

export const getBlockWidgetInfo = () => {
  return HTTPService.get(widgetBlocksApi);
};

// Comment Test1

/* console.log(
  "process.env.REACT_APP_BLOCK_BLOCK_HASH",
  process.env.REACT_APP_BLOCK_BLOCK_HASH
); */

/* export const getBlockWidgetInfo = async () => {
  return await axios.get(
    process.env.REACT_APP_BLOCK_BLOCK_HASH + `${widgetBlocksApi}`
  );
}; */

/* export const getBlockTableInfo = async () => {
  return HTTPService.get(tableBlocksApi);
}; */

export const getBlockTableInfo = async () => {
  return await axios.get(environment.newApiUrlTwo + `${tableBlocksApi}`);
};
